import PropTypes from "prop-types"
import React from "react"

const PartnerCard = ({ children, title, style, width }) => (
  <div style={style}>
    <div style={{
      backgroundColor: `#fff`,
      paddingBottom: `.8rem`,
      width: width
    }}>
      <div style={{
        textAlign: `center`,
        padding: `.5rem .6rem`,
        textTransform: `uppercase`,
        fontFamily: `Din Pro`,
        fontWeight: `bold`,
        color: `#d23464`,
        fontSize: `.7rem`
      }}>{title}</div>
      <div style={{
        backgroundColor: `#eee`,
        width: `100%`,
      }}>
        {children}
      </div>
    </div>
  </div>
)

PartnerCard.propTypes = {
  title: PropTypes.string,
}

PartnerCard.defaultProps = {
  title: `Official Partner`,
  width: `100%`
}

export default PartnerCard
