import React from "react"

const normal = `repeating-linear-gradient(45deg, #fff, #fff 4px, transparent 4px, transparent 9px)`
const fine = `repeating-linear-gradient(45deg, #fff, #fff 2px, transparent 2px, transparent 4px)`

const BandsDiamond = ({size, className, style, type}) => {
  if(type === 'fine') {
    return (
      <div className={className} style={style}>
        <div style={{
          width: size,
          height: size,
          background: fine,
          transform: `rotate(-45deg)`
        }} />
      </div>
    )
  } else {
    return (
      <div className={className} style={style}>
        <div style={{
          width: size,
          height: size,
          background: normal,
          transform: `rotate(-45deg)`
        }} />
      </div>
    )
  }
}



BandsDiamond.defaultProps = {
  size: `80px`,
}

export default BandsDiamond

// background: `repeating-linear-gradient(45deg, #fff, #fff 4px, transparent 4px, transparent 9px)`,
// background: `repeating-linear-gradient(45deg, #fff, #fff 2px, transparent 2px, transparent 4px)`,