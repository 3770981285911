import React from "react"
import { FaLinkedinIn } from 'react-icons/fa'

const LinkedInIcon = ({style}) => (
  <div style={style}>
    <div style={{
      border: `2px solid #fff`,
      width: `30px`,
      height: `30px`,
      borderRadius: `50%`,
      padding: `2px 5px`
    }}>
      <FaLinkedinIn size="16" />
    </div>
  </div>
)

export default LinkedInIcon