import React from "react"

const BandsRectangle = ({style}) => (
  <div style={style}>
    <div style={{
      width: `100%`,
      height: `100%`,
      background: `repeating-linear-gradient(45deg, #fff, #fff 4px, transparent 4px, transparent 9px)`,
    }} />
  </div>
)

export default BandsRectangle